export const ROOT = "/";
export const LOGIN ="/login";
export const OVERVIEW = "/product-overview";
export const PRICING = "/pricing";
export const ABOUT = "/our-story";
export const CONTACT = "/contact";
export const DASHBOARD = "/dashboard";
export const UNIQUE_GUEST_DATA = "/dashboard/guest/:id";
export const GUEST_LIST = "guest-list";
export const GUEST_REVIEW = "guest-review";
export const ROOT_API = "https://receptaredc.xyz/api";
export const LOGIN_API = "https://receptaredc.xyz/api/account/login";
export const DAY_GRAPH_API = "https://receptaredc.xyz/api/daygraph";
export const TIME_GRAPH_API = "https://receptaredc.xyz/api/timegraph";
export const GUESTDATA_API = "https://receptaredc.xyz/api/account/userlist";
export const SEARCH_GUEST_API = "https://receptaredc.xyz/api/account/userlist?search=";
export const GUEST_DATA_EXPORT = "https://receptaredc.xyz/api/account/export";
export const GUEST_EMAIL_EXPORT = "https://receptaredc.xyz/api/account/export/email";
export const GUEST_PHONE_EXPORT = "https://receptaredc.xyz/api/account/export/phone_number";
export const CSV_UPLOAD_API = "https://receptaredc.xyz/api/account/csv";
export const FORM_UPLOAD_API = "https://receptaredc.xyz/api/account/adduser";